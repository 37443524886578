.light-theme {
    // colors
    --ds-primary-dark-color: var(--ds-primary-dark-color-light);
    --ds-secondary-dark-color: var(--ds-secondary-dark-color-light);
    --ds-primary-light-color: var(--ds-primary-light-color-light);
    --ds-light-bg-color: var(--ds-primary-light-color-light);

    --ds-body-bg-color: var(--ds-body-bg-light-color);
    --ds-footer-bg-color: var(--ds-footer-bg-light-color);

    --ds-white-color: var(--ds-white-color-light);

    --ds-dark-text-color: var(--ds-primary-dark-color);
    --ds-secondary-text-color: var(--ds-secondary-dark-color);
    --ds-light-text-color: var(--ds-light-text-color-light);
    --ds-border-color: var(--ds-border-color-light);
    --ds-main-border: 1px solid var(--ds-border-color);
    --ds-dashed-border: 1px dashed var(--ds-border-color);


    --ds-form-control-focus-color: var(--ds-form-control-focus-color-light);

    // shadows
    --ds-light-shadow: var(--ds-light-shadow-light);
    --ds-medium-shadow: var(--ds-medium-shadow-light);
    --ds-dark-shadow: var(--ds-dark-shadow-light);

    //others
    --ds-product-actions-bg: var(--ds-product-actions-bg-light);
    --ds-maincategories-gradient: var(--ds-maincategories-gradient-light);
    --ds-popup-close-btn-bg: var(--ds-popup-close-btn-bg-light);
    --ds-popup-backdrop-bg: var(--ds-popup-backdrop-bg-light);
    --ds-product-hover-shadow: var(--ds-product-hover-shadow-light);
}