.ds-bannerplus {

    &-box {
        width: 100%;

        @media (min-width: $break-sm) {
            width: calc(100% / 2);
        }

        @media (min-width: $break-lg) {
            width: calc(100% / 4);
        }

        &.ds-banner-plus-100 {
            width: 100%;

            a {
                flex-direction: row !important;
                position: relative;
            }

            img {
                margin-right: 0 !important;
            }

            .ds-bannerplus-button {
                position: absolute;
                bottom: 2rem;
                left: 2rem;
            }
        }

        @media (min-width: $break-sm) {
            &.ds-banner-plus-50 {
                width: calc(100% / 2);
            }
        }

        @media (min-width: $break-lg) {
            &.ds-banner-plus-25 {
                width: calc(100% / 4);
            }
        }
    }

    &-item {
        box-shadow: var(--ds-light-shadow);
        color: var(--ds-white-color);
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.10) 52.72%, rgba(255, 255, 255, 0.00) 84.72%);
        padding: 2rem;
        gap: 2rem;

        p {
            letter-spacing: 0.6px;
        }

        &:hover {
            box-shadow: var(--ds-medium-shadow);

            .ds-bannerplus-button svg {
                transform: scale(1.5);
            }
        }
    }

    &-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
            transition: transform var(--ds-main-transition);

            @media (max-width: $break-xs-max) {
                transform: scale(1.2);
            }
        }
    }
}
