h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 700;
    color: var(--ds-dark-text-color);
    line-height: 1.5;
}

h1,
.h1 {
    font-size: 1.25rem;

    @media (min-width: $break-md) {
        font-size: 1.5rem;
    }
}

h2,
.h2 {
    font-size: 1.25rem;
}

h3,
.h3 {
    font-size: 1rem;
}

h4,
.h4 {
    font-size: 0.875rem;
}

h5,
.h5 {
    font-size: 0.75rem;
}

h6,
.h6 {
    font-size: 0.625rem;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fsz-8 {
    font-size: 0.5rem !important;
}

.fsz-10 {
    font-size: 0.625rem !important;
}

.fsz-12 {
    font-size: 0.75rem !important;
}

.fsz-14 {
    font-size: 0.875rem !important;
}

.fsz-16 {
    font-size: 1rem !important;
}

.fsz-18 {
    font-size: 1.125rem !important;
}

.fsz-20 {
    font-size: 1.25rem !important;
}

.fsz-22 {
    font-size: 1.375rem !important;
}

.fsz-24 {
    font-size: 1.5rem !important;
}

.fsz-32 {
    font-size: 2rem !important;
}

.fsz-36 {
    font-size: 2.25rem !important;
}