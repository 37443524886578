.ds-store-reviews {
    // стилі для сторінки з відгуками
    .ds-store-reviews-rating {
        @media (max-width: $break-sm-max) {
            padding-bottom: 4.5rem;
        }
    }

    .ds-store-reviews-item + .ds-store-reviews-item {
        margin-top: 1rem;
    }

    &-add {

        &-title {
            border-bottom: var(--ds-main-border);
        }
        
        @media (max-width: $break-smallest) {
            &-criteria-title {
                font-size: 0.75rem !important;
            }
        }

        .ds-module-rating-star {
            width: 1rem;
            height: 1rem;
            cursor: pointer;

            @media (min-width: $break-xxs) {
                width: 1.25rem;
                height: 1.25rem;
            }

            & + .ds-module-rating-star {
                margin-left: 0.5rem;

                @media (min-width: $break-xxs) {
                    margin-left: 0.75rem;
                }
            }
        }

        .button-primary {
            width: 100%;

            @media (min-width: $break-md) and (max-width: $break-lg-max) {
                width: auto ;
                padding-left: 3.75rem;
                padding-right: 3.75rem;
            }
        }
    }

    // стилі для модуля з відгуками
    &-header {
        border-bottom: var(--ds-main-border);
    }

    &-rating {
        border-bottom: var(--ds-main-border);

        &-main {
            @include content-block(2rem);
            border-radius: 1rem;
            width: 100%;

            @media (min-width: $break-sm) {
                width: auto;
            }

            &-value {
                font-size: 2.25rem;
                line-height: 3rem;
            }

            .ds-module-rating-stars {
                gap: 0.125rem;
            }

            .ds-module-rating-star {
                width: 1rem;
                height: 1rem;
            }
        }

        &-details {
            grid-template-columns: repeat(2, 1fr); // 4 стовпці, кожен 1/4 ширини
            grid-gap: 1rem;
            width: 100%;

            @media (min-width: $break-sm) {
                width: auto;
            }

            @media (min-width: $break-lg) {
                grid-template-columns: repeat(4, 1fr); // 4 стовпці, кожен 1/4 ширини
            }

            &-item {
                @include content-block(1rem);
                border-radius: 1rem;

                @media (min-width: $break-lg) {
                    padding: 1.5rem;
                }

                &-header {
                    border-bottom: var(--ds-main-border);
                }

                &-value {
                    font-size: 2rem;
                }

                .ds-module-rating-stars {
                    gap: 0.125rem;
                    margin-top: auto;
                }
            }
        }

        .button-primary {
            @media (max-width: $break-sm-max) {
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &-item {
        &-header {
            background: var(--ds-light-bg-color);
            border-bottom: var(--ds-main-border);

            .ds-module-rating-stars {
                gap: 0.125rem;
            }
        }

        &-answer {
            background: var(--ds-light-bg-color);
        }

        &-additional {
            > span {
                line-height: 1;
            }
        }

        &:hover {
            box-shadow: none;
        }
    }
}
