.ds-advantages {
    &-item {
        flex: 0 0 auto;
        width: 100%;

        @media (min-width: $break-md) {
            width: calc(100% / 2);
        }

        @media (min-width: $break-lg) {
            width: calc(100% / 3);
        }

        @media (min-width: $break-xl) {
            width: calc(100% / 6);
        }

        &:hover a {
            text-decoration: underline;
            color: var(--ds-links-color);
        }
    }
}