.ds-last-news {
    &-item {
        flex: 0 0 auto;
        width: 100%;

        @media (min-width: $break-sm) {
            width: calc(100% / 2);
        }

        @media (min-width: $break-md) {
            width: calc(100% / 3);
        }

        @media (min-width: $break-lg) {
            width: calc(100% / 4);
        }

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 1 / 1;
            border-radius: 0.25rem;
            border: var(--ds-main-border);
            transition: opacity var(--ds-main-transition);

            &:hover {
                opacity: 0.95;
            }

            @media (max-width: $break-xs-max) {
                width: 80px;
                margin-right: 1rem;
            }
        }

        &-title {
            line-height: 1.125rem;

            &:hover {
                text-decoration: underline;
            }
        }

        &:hover {
            .ds-last-news-item-title {
                color: var(--ds-links-color);
            }
        }
    }
}