.ds-last-reviews {
    &-item {
        flex: 0 0 auto;
        width: 100%;

        @media (min-width: $break-md) {
            width: calc(100% / 2);
        }

        &-top {
            border-bottom: var(--ds-main-border);
        }

        img {
            border-radius: 0.25rem;
            border: var(--ds-main-border);
        }
        
        &-img-label {
            padding: 0.25rem 0.5rem;
            gap: 0.25rem;
            opacity: 0.8;
            line-height: 1;
            top: 0.5rem;
            left: 0.75rem;
            right: 0.75rem;
        }

        &-info {
            line-height: 1;
        }

        a:hover {
            text-decoration: none;
            color: var(--ds-links-color);
        }
    }
}