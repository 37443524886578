.ds-category-wall {

    &-item {
        flex: 0 0 auto;
        width: 100%;

        @media (min-width: $break-sm) {
            width: calc(100% / 2);
        }

        @media (min-width: $break-lg) {
            width: calc(100% / 3);
        }

        .content-block {
            position: relative;
            min-height: 100%;

            @media (min-width: $break-md) {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
            }

            > a {
                margin-bottom: 1rem;
            }
        }

        img {
            border-radius: 0.25rem;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            gap: 0.25rem;

            a {
                border: var(--ds-main-border);
                transition: border-color var(--ds-main-transition), color var(--ds-main-transition);

                &:hover {
                    border-color: var(--ds-primary-color);
                    color: var(--ds-primary-color);
                }
            }
        }
    }

    &-title {
        font-size: 0.625rem;
        line-height: 1;
        text-decoration: underline;

        @media (min-width: $break-md) {
            font-size: 0.875rem;
        }

        &:hover {
            color: var(--ds-links-color);
        }
    }
}