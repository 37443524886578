.form {
    &-label {
        color: var(--ds-dark-text-color);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0.5rem;
        display: block;
    }

    &-control {
        padding: 0.75rem 1rem;
        color: var(--ds-dark-text-color);
        font-size: 0.875rem;
        font-weight: 400;
        border: var(--ds-main-border);
        background-color: var(--ds-primary-light-color);

        @media (max-width: $break-lg-max) {
            transition: unset;
        }

        &::placeholder {
            color: var(--ds-light-text-color);
            font-size: 0.875rem;
        }

        &:focus {
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.02);
            border: 1px solid var(--ds-form-control-focus-color);
            color: var(--ds-dark-text-color);
        }

        &:disabled {
            background-color: #f2f4f8;
            border-color: #f2f4f8;
            color: #808080;
        }
    }

    &-select {
        border: var(--ds-main-border);
        background-color: var(--ds-primary-light-color);
        font-size: 0.875rem;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 2rem;
    }

    &-check {
        color: var(--ds-secondary-dark-color);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        & + & {
            margin-top: 0.25rem;
        }

        &-input {
            border-color: var(--ds-light-text-color);
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.5rem;
            margin-top: 0;
            background-color: var(--ds-white-color);

            &:checked {
                background-color: var(--ds-primary-color);
                border-color: var(--ds-primary-color);
            }
        }

        &-label {
            a {
                color: var(--ds-links-color);
                text-decoration: underline;
                font-weight: 300;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.input-group-text {
    border-color: var(--ds-border-color);
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
    z-index: 6;
    width: 2.5rem;
    display: grid;
    place-items: center;

    svg path {
        fill: var(--ds-secondary-dark-color);
    }

    &:after {
        content: "";
        position: absolute;
        top: 0.75rem;
        bottom: 0.75rem;
        right: 0;
        width: 1px;
        background-color: var(--ds-border-color);
        z-index: 7;
    }

    & + .form-control {
        border-radius: 7px !important;
        padding-left: 3rem;
    }
}

textarea {
    &[name="positive_text"] {
        color: var(--ds-green-color);

        &::placeholder,
        &:focus {
            color: var(--ds-green-color);
        }
    }

    &[name="negative_text"] {
        color: var(--ds-red-color);

        &::placeholder,
        &:focus {
            color: var(--ds-red-color);
        }
    }
}