*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    outline: none;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul,
li {
    padding: 0;
}

a,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: $break-lg) {
        transition: color var(--ds-main-transition);
    }
}

button {
    cursor: pointer;
    border: 0;
}

@supports (-webkit-touch-callout: none) {

    .form-control,
    input[type=text] {
        @media (max-width: $break-sm-max) {
            font-size: 1rem !important;
        }
    }
}

div,
p,
form,
input,
a,
span,
button {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

form {
    margin-block-end: 0;
}

input,
input:hover,
input:active,
button,
button:hover,
button:active,
.btn,
.btn:hover {
    outline: none;
    box-shadow: none;
}

textarea,
input[type="text"],
input[type="email"] {
    -webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}
