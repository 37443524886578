// min-width breakpoints
$break-xxs: 360px; // @media (min-width: $break-xxs) {}
$break-xs: 420px; // @media (min-width: $break-xs) {}
$break-sm: 576px; // @media (min-width: $break-sm) {}
$break-md: 768px; // @media (min-width: $break-md) {}
$break-lg: 992px; // @media (min-width: $break-lg) {}
$break-xl: 1200px; // @media (min-width: $break-xl) {}
$break-xxl: 1600px; // @media (min-width: $break-xxl) {}

// max-width breakpoints
$break-smallest: 359.98px; // @media (max-width: $break-smallest) {}
$break-xxs-max: 419.98px; // @media (max-width: $break-xxs-max) {}
$break-xs-max: 575.98px; // @media (max-width: $break-xs-max) {}
$break-sm-max: 767.98px; // @media (max-width: $break-sm-max) {}
$break-md-max: 991.98px; // @media (max-width: $break-md-max) {}
$break-lg-max: 1199.98px; // @media (max-width: $break-lg-max) {}
$break-xl-max: 1599.98px; // @media (max-width: $break-xl-max) {}

:root {
    // main colors
    --ds-primary-color: #00A8E8;
    --ds-green-color: #52BB38;
    --ds-red-color: #D5383D;
    --ds-yellow-color: #FBC756;
    --ds-blue-color: #00A8E8;

    --ds-body-bg-light-color: #F8FBFD;
    --ds-body-bg-dark-color: #1b1b1b;
    --ds-footer-bg-light-color: #FFFFFF;
    --ds-footer-bg-dark-color: var(--ds-white-color-dark);
    
    --ds-links-color: var(--ds-blue-color);

    --ds-error-bg-color: #FFEEF5;
    --ds-error-border-color: #FE4D97;
    --ds-interactive-color: var(--ds-green-color);

    // fonts
    --ds-main-font: 'Ubuntu', sans-serif;

    // misc
    --ds-main-transition: 0.3s ease-out;

    // theme colors
    --ds-primary-dark-color-light: #00171F;
    --ds-primary-dark-color-dark: #F8FCFF;

    --ds-secondary-dark-color-light: #003459;
    --ds-secondary-dark-color-dark: #909090;

    --ds-primary-light-color-light: #F8FBFD;
    --ds-primary-light-color-dark: #1b1b1b;

    --ds-white-color-light: #FFFFFF;
    --ds-white-color-dark: #141414;

    --ds-light-text-color-light: #9CA3AF;
    --ds-light-text-color-dark: #5f5f5f;

    --ds-border-color-light: #F2F2F2;
    --ds-border-color-dark: #262626;

    --ds-form-control-focus-color-light: #d3d8e7;
    --ds-form-control-focus-color-dark: #333333;

    --ds-light-shadow-light: 0px 2px 14px 0px rgba(37, 38, 39, 0.02);
    --ds-light-shadow-dark: 0px 2px 14px 0px rgba(218, 217, 216, 0.02);

    --ds-medium-shadow-light: 0px 4px 20px 0px rgba(37, 38, 39, 0.10);
    --ds-medium-shadow-dark: 0px 4px 20px 0px rgba(218, 217, 216, 0.10);

    --ds-dark-shadow-light: 0px 2px 24px 0px rgba(37, 38, 39, 0.08);
    --ds-dark-shadow-dark: 0px 2px 24px 0px rgba(218, 217, 216, 0.08);

    --ds-product-actions-bg-light: #f9fdff;
    --ds-product-actions-bg-dark: #0c0c0c;

    --ds-maincategories-gradient-light: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
    --ds-maincategories-gradient-dark: linear-gradient(90deg, rgba(0, 0, 0, 0), var(--ds-white-color));


    --ds-popup-close-btn-bg-light: #F2FDFF;
    --ds-popup-close-btn-bg-dark: #434444;


    --ds-popup-backdrop-bg-light: rgba(17, 19, 20, 0.72);
    --ds-popup-backdrop-bg-dark: rgba(188, 188, 188, 0.3);

    --ds-product-hover-shadow-light: 0px 4px 4px 0px rgba(37, 38, 39, 0.10);
    --ds-product-hover-shadow-dark: 0px 4px 4px 0px rgba(218, 217, 216, 0.10);

}
