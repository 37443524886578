.ds-product-search {
    &-form,
    &-form .form-group,
    &-form .form-checkbox-group {
        gap: 0.75rem;
    }

    .button-primary {
        @media (min-width: $break-sm) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}