.ds-product {
    &-tabs {
        border: var(--ds-main-border);
        background: var(--ds-white-color);
        top: 51px;
        padding: 0.75rem 0;
        border-radius: 0.25rem 0.25rem 0 0;

        &-box {
            max-width: 100%;
            overflow-x: auto;
            gap: 0.5rem;

            &::-webkit-scrollbar {
                height: 0;
                
            }
            
            @media (min-width: $break-xl) {
                gap: 0.75rem;
            }
        }

        &-item {
            background-color: #FDFDFD;
            border: var(--ds-main-border);
            padding: 0.5rem 1rem;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            flex: 0 0 auto;
            cursor: pointer;
            line-height: 1;
            transition: background-color var(--ds-main-transition), color var(--ds-main-transition), border var(--ds-main-transition);
            font-size: 0.75rem;

            @media (min-width: $break-xl) {
                padding: 0.75rem 1.5rem;
                font-size: 0.875rem;
            }

            > * {
                pointer-events: none;
            }

            &.active {
                box-shadow: var(--ds-medium-shadow);
                font-weight: 700;
                color: var(--ds-dark-text-color);
                background-color: var(--ds-white-color);

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: var(--ds-primary-color);
                }
            }
        }

        &-badge {
            height: 12px;
            min-width: 12px;
            place-items: center;
            color: var(--ds-white-color);
            font-size: 0.375rem;
            background: var(--ds-light-text-color);
            border-radius: 50%;
            line-height: 1;
        }
    }

    &-fixed-btns {
        background: var(--ds-white-color);
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        padding-bottom: 84px;
        transform: translateY(100%);
        transition: transform var(--ds-main-transition);

        &.enabled {
            transform: translateY(0);
            border-top: var(--ds-main-border);
        }

        .button {
            width: auto;
            height: 32px;
            padding: 0.5rem 1rem;
            margin-right: 1px;

            @media (max-width: $break-xxs-max) {
                &.sc-product-fixed-cart-btn {
                    .button-text {
                        display: none;
                    }
                }
            }
        }

        & + footer {
            padding-bottom: 84px !important;

            @media (min-width: $break-lg) {
                padding-bottom: 1.5rem !important;
            }
        }
    }

    &-top-info {
        gap: 0.5rem;
        line-height: 1;
    }

    &-stickers {
        @media (max-width: $break-sm-max) {
            .ds-module-sticker {
                font-size: .625rem;
            }
        }
    }

    &-images {
        flex: 0 0 100%;
        max-width: 100%;
        
        @media (max-width: $break-sm) {
            min-height: 356px;
        }

        @media (min-width: $break-md) {
            flex: 0 0 calc(50% - 1rem);
            max-width: calc(50% - 1rem);
            border-right: var(--ds-main-border);
        }

        @media (min-width: $break-lg) {
            flex: 0 0 calc(60% - 1rem);
            max-width: calc(60% - 1rem);
        }

        &-main {

            @media (min-width: $break-xl) {
                padding-top: 1rem;
                height: 700px;
                overflow: hidden;

                &--multiple {
                    padding-left: 96px;
                }
            }

            .zoomImg {
                @media (max-width: $break-sm-max) {
                    display: none !important;
                }
            }

            @media (max-width: $break-lg-max) {
                .swiper-button-next, .swiper-button-prev {
                    display: none;
                }

                .swiper-pagination {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.125rem;

                    &-bullet {
                        background: var(--ds-light-text-color) !important;
                        opacity: 1;
                        transition: transform var(--ds-main-transition), background-color var(--ds-main-transition);
                        
                        &-active {
                            background: var(--ds-secondary-dark-color) !important;
                            transform: scale(1.25);
                        }
                    }
                }
            }

            .swiper {
                visibility: hidden;

                &.swiper-initialized {
                    visibility: visible;
                }
            }

            .oct-gallery {
                cursor: pointer;
            }
        }

        &-slide {
            img {
                max-width: 100%;
                object-fit: contain;
                height: auto;
            }
        }

        &-additional {
            z-index: 2;
            transition: opacity 1s ease;
            height: 630px;
            width: 80px;

            @media (min-width: $break-md) {
                padding-top: 1rem;
            }

            &-item {
                cursor: pointer;

                img {
                    object-fit: contain;
                    border: var(--ds-main-border);
                    border-radius: 0.125rem;

                    @media (max-width: $break-lg-max) {
                        width: calc(100% - 6px);
                    }
                }
            }

            .swiper {
                @media (min-width: $break-xl) {
                    padding: 1.5rem 0 2rem;
                    overflow: hidden;
                }

                &-slide {
                    min-height: 80px;
                    
                    &-thumb-active {
                        img {
                            border-color: var(--ds-light-text-color);
                        }
                    }

                    &:not(.swiper-slide-visible) {
                        img {
                            opacity: 0;
                        }
                    }
                }

                &-slider-btn {
                    width: 80px;
                    height: 1rem;
                    background: var(--ds-white-color);
                    border: var(--ds-main-border);
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    cursor: pointer;
                    transition: background-color var(--ds-main-transition), color var(--ds-main-transition), border var(--ds-main-transition);
                    position: absolute;
                    z-index: 11;
                    outline: none;
                    user-select: none;
                    
    
                    &-prev {
                        top: 0;
                    }
    
                    &-next {
                        bottom: 0;
                    }
    
                    &:hover {
                        background-color: var(--ds-primary-color);
                        color: var(--ds-white-color);
                        border-color: var(--ds-primary-color);
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
    
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    &-main {
        @media (min-width: $break-md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media (min-width: $break-lg) {
            flex: 0 0 calc(40% - 0.25rem);
            max-width: calc(40% - 0.25rem);
        }

        @media (min-width: $break-xl) {
            flex: 0 0 calc(40% - 0.5rem);
            max-width: calc(40% - 0.5rem);
        }

        &-attributes {
            gap: 0.5rem;
            border-top: var(--ds-dashed-border);

            &-item {
                background-color: var(--ds-light-bg-color);
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-stock {
            padding: 0.5rem 0.75rem;
            background: var(--ds-light-bg-color);
            line-height: 1;
            font-size: 0.625rem;

            @media (min-width: $break-lg) {
                font-size: 0.75rem;

                svg {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }

        &-actions {
            background: var(--ds-product-actions-bg);

            &:hover {
                box-shadow: none;
            }
        }

        &-buttons {
            flex-direction: column;

            @media (min-width: $break-sm) {
                flex-direction: row;
            }

            @media (min-width: $break-md) {
                flex-direction: column;
            }

            @media (min-width: $break-xxl) {
                flex-direction: row;
            }
        }

        &-cart {
            flex-grow: 1;

            @media (max-width: $break-smallest) {
                flex-direction: column;

                .ds-module-quantity {
                    margin-right: 0 !important;
                    margin-bottom: 1rem;
                }
            }

            &-button {
                @media (min-width: $break-sm) {
                    height: 42px;
                }
                svg {
                    width: 1rem;
                    height: 1rem;
                    flex: 0 0 auto;
                }
            }
        }

        &-content {
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            &:hover {
                box-shadow: none;
            }
        }

        .ds-buy-one-click {
            @media (min-width: $break-md) {
                padding-top: 1.5rem;
            }

            form {
                flex-grow: 1;
            }
        }

        .ds-stock-notifier-btn {
            @media (max-width: $break-sm-max) {
                width: 100%;
            }
        }
    }

    &-wishlist,
    &-compare {
        &.added,
        &.added:hover {
            background-color: var(--ds-interactive-color);

            svg path {
                fill: var(--ds-white-color);
            }
        }
    }

    &-wishlist-compare {
        gap: 0.5rem;

        >* {
            flex: 0 0 auto;
            padding: 0.5rem;
        }
    }

    &-timer {
        border: 1px dashed var(--ds-red-color);

        &-inner > .animated {
            animation: seconds 1s infinite;
        }

        &-number {
            width: 1.5rem;
            text-align: center;
        }

        &-delimiter {
            padding: 0 0.125rem;
        }
    }

    @keyframes seconds {
        0% {
            opacity: 0
        }
    
        25% {
            opacity: 1
        }
    
    
        75% {
            opacity: 0
        }
    
        100% {
            opacity: 0
        }
    }

    &-options {
        border-bottom: var(--ds-main-border);

        .form-group+.form-group {
            margin-top: 1rem;
        }

        .form-control {
            background: var(--ds-white-color);
        }

        .options-box {
            gap: 0.5rem;
        }

        .radio {
            label {
                position: relative;
                cursor: pointer;
                padding: 0.375rem 0.75rem;
                color: var(--ds-secondary-text-color);
                font-size: 0.75rem;
                line-height: normal;
                background: var(--ds-light-bg-color);
                border: var(--ds-main-border);
                border-radius: 7px;
                cursor: pointer;
                transition: background var(--ds-main-transition), border var(--ds-main-transition), color var(--ds-main-transition);
                white-space: nowrap;

                &.selected {
                    background: var(--ds-light-bg-color);
                    color: var(--ds-primary-color);
                    border: 1px solid var(--ds-primary-color);
                }

                &.radio-img {
                    border: var(--ds-main-border);
                    border-width: 2px;
                    border-radius: 1rem;
                    width: 1.75rem;
                    height: 1.75rem;
                    padding: 0;

                    &.selected {
                        border: 2px solid var(--ds-primary-color);
                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 1rem;
                        pointer-events: none;
                    }
                }
            }
        }

        .input-radio {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);

            &:checked+.radio-box::after {
                opacity: 1;
            }

            &:focus+.radio-box {
                box-shadow: inset 0 0 0 0.1rem rgba(198, 198, 198, 0.1);
            }
        }

        .form-check-label {
            img + .form-check-input {
                margin-left: 0.5rem;
            }
        }
    }

    &-fast-order-button {
        margin-top: 1rem;

        @media (min-width: $break-sm) {
            margin-top: 0;
            margin-left: 1rem;
        }

        @media (min-width: $break-md) {
            margin-top: 1rem;
            margin-left: 0;
        }

        @media (min-width: $break-lg) {
            margin-top: 1.5rem;
        }

        @media (min-width: $break-xxl) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &-advantages {
        &:hover {
            box-shadow: none;
        }

        &-item {
            flex: 1 1 auto;

            & + & {
                border-top: var(--ds-main-border);
            }

            &-text {

                > div {
                    max-width: 100%;
                    word-break: break-word;
                }

                & + & {
                    border-top: var(--ds-dashed-border);
                }

                a {
                    &.no-btn {
                        flex: 0 0 1.25rem;
                        width: 1.25rem;

                        svg path {
                            transition: fill var(--ds-main-transition);
                        }

                        &:hover,
                        &:focus:not(:disabled) {
                            box-shadow: none;
                            
                            svg path {
                                fill: var(--ds-primary-color);
                            }
                        }
                    }

                    &:hover {
                        color: var(--ds-links-color);
                    }
                }

                &-price {
                    min-width: 80px;
                }

                &-img {
                    flex: 0 0 20px;
                    width: 20px;
                }
            }
        }

        &-payments {
            &-item {
                flex: 0 0 auto;
                border: var(--ds-main-border);
            }
        }
    }

    &-block {
        z-index: 1010;

        &:hover {
            .ds-module-title {
                color: var(--ds-links-color);

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-kit {
            padding-bottom: 1rem;
            border: 2px solid var(--ds-primary-color);
            
            &.with-bottom-padding {
                padding-bottom: 1rem;
            }
            
            .product-set-data-swiper {
                display: flex;
                max-width: 100%;

                @media (min-width: $break-sm) {
                    align-items: center;
                }
            }

            &-items {
                gap: 0.5rem;

                @media (min-width: $break-sm) {
                    gap: 1rem;
                }

                @media (max-width: $break-xs-max) {
                    width: 100%;
                }
            }

            &-item {
                @media (max-width: $break-xs-max) {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding-bottom: 1rem !important;
                    border-radius: 0 !important;

                    &:hover {
                        border-color: var(--ds-border-color) !important;
                    }
                }
                @media (max-width: $break-lg-max) {
                    align-items: stretch;

                    .ds-module-img {
                        flex: 0 0 96px;
                        height: 100%;
                        justify-content: center;

                        @media (min-width: $break-sm) {
                            padding-right: 1rem;
                            margin-right: 1rem;
                            border-right: var(--ds-main-border);
                        }

                        img {
                            width: 88px;
                            height: 88px;
                        }
                    }

                    .ds-module-caption {
                        padding-left: 1rem;
                        justify-content: center;
                        padding-top: 0;
                    }

                    .ds-module-price {
                        padding-top: 0.5rem;
                    }
                }

                @media (min-width: $break-xl) {
                    &.flex-row {
                        flex: 0 0 calc(50% - 3rem);
                        max-width: calc(50% - 3rem);
                        
                        .ds-module-img {
                            padding-right: 1rem;
                            margin-right: 1rem;
                            border-right: var(--ds-main-border);
                            flex: 0 0 96px;
                            height: 100%;
                            justify-content: center;

                            img {
                                width: 88px;
                                height: 88px;
                            }
                        }

                        .ds-module-caption {
                            padding-left: 1rem;
                            justify-content: center;
                            align-self: stretch;
                        }
                    }

                    &.flex-xl-column {
                        flex: 1;

                        .ds-module-title {
                            padding: 1rem 0;
                        }

                        .ds-module-price {
                            border-top: var(--ds-dashed-border);
                            padding-top: 1rem;
                        }
                    }
                }
            }
            
            .content-block {
                position: relative;
                transition: border-color var(--ds-main-transition);

                &:hover {
                    box-shadow: none;
                    border-color: var(--ds-form-control-focus-color);
                }
            }

            &-prices {
                white-space: nowrap;

                .content-block {
                    border-width: 0;
                }
            }

            @media (max-width: $break-xs-max) {
                &-plus-icon,
                &-equal-icon {
                    position: relative;

                    svg {
                        position: absolute;
                        background: var(--ds-white-color);
                        padding: 0.25rem 0.5rem;
                        top: -24.5px;
                    }
                }

                &-plus-icon {
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }

                &-equal-icon svg {
                    width: 32px;
                    height: 16px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &-equal-icon {
                top: -0.5rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;

                .ds-product-block-kit-equal-icon svg path {
                    fill: var(--ds-red-color);
                }

                @media (min-width: $break-md) {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }

            .ds-store-reviews-rating-details-item {
                @media (min-width: $break-sm) {
                    min-width: 316px;
                }

                @media (min-width: $break-md) and (max-width: $break-lg-max) {
                    flex: 0 0 50%;
                }
            }

            .rounded-circle {
                border: var(--ds-main-border);
                background: var(--ds-light-bg-color);
                width: 1.75rem;
                height: 1.75rem;
                top: 0;
                right: 0;
            }

            .button-primary {
                @media (max-width: $break-xs-max) {
                    width: auto;
                    margin-left: auto;
                    height: 38px;
                }
            }
        }
    }

    &-tab-content {
        &-title {
            border-bottom: var(--ds-main-border);

            svg path {
                fill: var(--ds-dark-text-color);
            }
        }

        & + & {
            margin-top: 1rem;
        }

        &:hover {
            box-shadow: none;
        }

        a {
            color: var(--ds-links-color);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-attributes {

        &-item {
            gap: 0.5rem;
            align-items: center;
            padding: 1rem;

            &:nth-child(odd) {
                background: var(--ds-light-bg-color);
            }

            > * {
                &:first-child {
                    white-space: nowrap;
                    overflow: hidden;
                    width: 60%;
                    z-index: 2;
                    position: relative;

                    span {
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        hyphens: auto;
                    }

                    @media (min-width: $break-sm) {
                        width: 50%;
                    }

                    &::after {
                        content: '................................................................................................................................................................................................................................................................................................................................................................................................................................................';
                        overflow: hidden;
                        color: var(--ds-light-text-color);
                        padding: 0 0.5rem;
                        width: calc(100% - 1rem);
                        font-size: 0.75rem;
                        position: absolute;
                        padding-top: 0.225rem;
                    }
                }

                &:last-child {
                    width: 38%;
                    hyphens: auto;
                    word-break: break-word;

                    @media (min-width: $break-xl) {
                        width: 50%;
                    }
                }
            }
        }
    }

    &-reviews {

        &-top {
            border-bottom: var(--ds-main-border);

            > * {
                flex: 1 1 auto;
            }

            .ds-module-rating-star {
                width: 1rem;
                height: 1rem;

                &+.ds-module-rating-star {
                    margin-left: 0.25rem;
                }
            }

            .button-outline {
                @media (max-width: $break-xs-max) {
                    width: 100%;
                }
            }
        }

        &-rating {

            &-item {
                background: var(--ds-light-bg-color);
                gap: 0.5rem;
                width: 100%;
                max-width: 360px;
                
                @media (max-width: $break-sm-max) {
                    margin: 2rem auto 0;
                }

                & + & {
                    margin-top: 0.5rem;
                }

                &-line {
                    height: 6px;
                    background: #D1D5DB;
                    position: relative;
                    overflow: hidden;
                    
                    &-value {
                        height: 100%;
                        background: var(--ds-green-color);
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                    }
                }

                svg {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }

        &-item {
            background: var(--ds-light-bg-color);

            &-box {
                & + & {
                    border-top: var(--ds-dashed-border);
                    margin-top: 1rem;
                    padding-top: 1rem;

                    @media (min-width: $break-md) {
                        margin-top: 1.5rem;
                        padding-top: 1.5rem;
                    }
                }



                .ds-product-reviews-item-content,
                .ds-product-reviews-item-content p {
                    color: var(--ds-dark-text-color);
                }
            }

            &-header {
                line-height: 1;
            }

            &-header,
            &-content {
                border-bottom: var(--ds-dashed-border);
            }

            &-like {
                cursor: pointer;

                &:hover {
                    > *:first-child {
                        color: var(--ds-links-color);
                    }
                }
            }

            &-answer {
                margin-left: 2rem;

                @media (min-width: $break-md) {
                    margin-left: 4rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -0.75rem;
                    left: 2rem;
                    border-left: 0.5rem solid transparent;
                    border-right: 0.5rem solid transparent;
                    border-bottom: 0.75rem solid var(--ds-light-bg-color);
                    width: 0;
                    height: 0;
                
                }

                .ds-product-reviews-item-content {
                    border-bottom: 0;
                }
            }
        }
    }

    &-rating {
        @media (min-width: $break-md) {
            padding-left: 2rem;
        }
    }

    &-faq {
        &-top {
            border-bottom: var(--ds-main-border);

            .button-outline {
                @media (max-width: $break-xs-max) {
                    width: 100%;
                }
            }
        }

        &-content {
            .ds-product-reviews-item-content {
                border-bottom: 0;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .product-content-row #content > .row:not(.product-tabs-row) {
        display: flex;
        flex-direction: column;
    }
    
    
    
    .product-content-row #content > .row:not(.product-tabs-row) > * {
        width: 100%;
    }
    
    .product-content-row #content > .row:not(.product-tabs-row) > * + * {
        margin-top:  16px;
    }
}