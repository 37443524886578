.ds-contacts {
    &-column {
        &-text {
            border-bottom: var(--ds-main-border);
        }

        .button-primary {
            width: 100%;

            @media (min-width: $break-sm) and (max-width: $break-md-max) {
                max-width: 300px;
            }
        }
    }

    &-item {
        &-info {
            &-title {
                line-height: 1;
                white-space: nowrap;

                svg {
                    margin-right: 0.5rem;

                    path {
                        fill: var(--ds-dark-text-color);
                    }
                }

                li {
                    white-space: nowrap;
                }
            }
        }

        &-img {
            img {
                object-fit: cover;
                max-width: 100%;

                @media (min-width: $break-md) and (max-width: $break-lg-max) {
                    width: 300px;
                }
            }
        }

        &-socials {
            gap: 0.5rem;
        }

        &-social {
            width: 2rem;
            height: 2rem;
            display: grid;
            place-items: center;

            svg {
                margin-right: 0 !important;
            }
        }

        &-box {
            border-bottom: var(--ds-main-border);
        }

        iframe {
            width: 100%;
        }
    }

}