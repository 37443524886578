@mixin content-block($padding:"1rem") {
    border: var(--ds-main-border);
    background-color: var(--ds-white-color);
    color: var(--ds-light-text-color);
    padding: #{$padding};
    transition: box-shadow var(--ds-main-transition);
    border-radius: 0.25rem;

    &:hover {
        box-shadow: var(--ds-medium-shadow);
    }
}

@mixin btn_anim {
    transition: background var(--ds-main-transition), color var(--ds-main-transition), filter var(--ds-main-transition);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    &:focus {
        outline: transparent;
    }
}

@mixin no-btn ($display:"inline-block") {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
    display: #{$display};
}

@mixin button-hover-active {
    &:hover:not([disabled]),
    &:active:not([disabled]) {
        &::before {
            opacity: 1;
            transform: scale(4);
        }

        text-shadow: 0 0 10px #e5e5e5a1, 0 0 20px #f5f5f577;
    }
}

@mixin blue-link {
    color: var(--ds-links-color);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: var(--ds-links-color);
    }
}
