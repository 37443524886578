.ds-slideshow {

    &.menu-active {
        position: relative;
        z-index: 4;
        
        @media (min-width: $break-xl) {
            padding-left: calc(25% + 1.875rem);
        }

        @media (min-width: 1400px) {
            padding-left: calc(25% + 1rem);
        }

    }

    &-items {
        &-vertical {
            min-height: 430px;
            visibility: hidden;

            @media (min-width: $break-xxl) {
                min-height: 560px;
            }

            &.swiper-initialized {
                visibility: visible;
            }
        }
    }

    &-item {
        height: 100%;
        background: #102432 linear-gradient(270deg, rgba(255, 255, 255, 0.10) 52.72%, rgba(255, 255, 255, 0.00) 84.72%);
        padding: 2rem;
        color: var(--ds-white-color);

        &-vertical:not(:last-child) {
            margin-right: 1rem;
        }

        .button.button-outline-primary {
            border-color: var(--ds-white-color);
            color: var(--ds-white-color);

            &:hover {
                background-color: var(--ds-white-color);
                color: var(--ds-secondary-dark-color);
            }
        }

        &-title,
        &-text {
            align-self: flex-start;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            max-width: 240px;
            max-height: 240px;
        }

        &-full-img {
            
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-horizontal {
            min-height: 300px;
        }
    }

    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem .75rem;
        background: var(--ds-white-color);
        border-radius: 7px;
        gap: 0.125rem;
        width: auto !important;
        left: 50% !important;
        transform: translateX(-50%);

        &-bullet {
            background: var(--ds-light-text-color) !important;
            opacity: 1;
            transition: transform var(--ds-main-transition), background-color var(--ds-main-transition);
            
            &-active {
                background: var(--ds-secondary-dark-color) !important;
                transform: scale(1.25);
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        outline: none;
        user-select: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::after {
            display: none;
        }

        svg path {
            fill: var(--ds-secondary-dark-color);
        }
    }

    .swiper-button-next svg {
        transform: rotate(180deg);
    }

    .swiper-wrapper-grid {
        display: grid;
        grid-auto-flow: column;
        grid-auto-rows: 1fr;
    }
}