.ds-mobile-bottom-nav {
    border-radius: 1rem 1rem 0 0;
    background: var(--ds-white-color);
    box-shadow: var(--ds-light-shadow);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    box-shadow: 0px -4px 10px 0px rgba(40, 44, 49, 0.04);
    border-top: 1px solid var(--ds-border-color);
    gap: 0.5rem;
    min-height: 69px;

    &-item {
        width: 52px;
        height: 52px;
        cursor: pointer;

        > * {
            pointer-events: none;
        }

        &-icon {
            width: 14px;
            height: 14px;
            display: grid;
            place-content: center;
        }

        &-title {
            font-size: 0.5rem;

            @media (min-width: $break-md) {
                font-size: 0.625rem;
            }
        }

        &-badge {
            background-color: var(--ds-light-text-color);
            color: #fff;
            top: 4px;
            right: 6px;
            min-width: 0.75rem;
            padding: 0.125rem;
            border-radius: 1.5rem;
            line-height: 1;
            font-size: 0.5rem;
        }

        &-catalog {
            background: var(--ds-popup-close-btn-bg);
            color: var(--ds-primary-color);

            svg path {
                fill: var(--ds-primary-color);
            }
        }
    }
}