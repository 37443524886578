#logo {
    block-size: auto;
    max-width: 98px;

    @media (min-width: $break-xl) {
        max-width: 74px;
    }

    @media (min-width: $break-xxl) {
        max-width: 106px;
    }

    @media (min-width: 1600px) {
        max-width: 134px;
    }
}

.ds-header {
    background: var(--ds-white-color);
    box-shadow: var(--ds-medium-shadow);
    z-index: 12300;

    @media (max-width: $break-sm-max) {
        min-height: 46px;
    }

    &:not(.sticky-top) {
        position: relative;
    }

    &.with-categories-menu {
        @media (min-width: $break-xl) {
            .ds-menu-maincategories-desktop-box {
                height: 46px;
            }

            .ds-menu-main-catalog {
                top: calc(100% + 1rem) !important;

                &.with-slideshow {
                    top: calc(100% + 78px) !important;
                }
            }
        }
    }

    &-left {
        flex: 0 0 auto;

        @media (min-width: $break-xl) {
            position: relative;
            flex: 0 0 25%;

            &.with-menu-btn {
                flex: 0 0 auto;
            }
        }
    }

    &-right > .button-transparent {
        height: 20px;
        display: grid;
        place-items: center;
        
        > svg path {
            transition: fill var(--ds-main-transition);
        }

        &:hover,
        &:focus:not([disabled]) {
            box-shadow: none;
            
            > svg path {
                fill: var(--ds-primary-color);
            }
        }
    }

    &-menu-button {
        flex: 0 0 1.25rem;
    }

    &-catalog-button {
        border-width: 2px;
        width: 100%;
    }

    &-search {
        position: fixed;
        z-index: 2;

        @media (max-width: $break-sm-max) {
            left: 2.5rem;
            right: 2.5rem;
            top: 0;
            transform: translateX(100%);
            transition: transform var(--ds-main-transition);
            width: 0;
            overflow: hidden;
            background: var(--ds-white-color);
            display: flex;
        }

        @media (min-width: $break-md) {
            flex-grow: 1;
            position: relative;
        }

        &.active {
            width: auto;
            overflow: visible;
            transform: translateX(0);
            z-index: 13000;
        }

        .form-control {
            padding: 1rem 1.75rem;
            border: 2px solid var(--ds-border-color);
            background: var(--ds-light-bg-color);
            height: 2.25rem;

            @media (min-width: $break-md) {
                padding: 0.3125rem 1rem;
            }

            &::placeholder {
                font-size: 0.75rem;
            }

            &:focus {
                border-width: 2px;
                box-shadow: none;
                background: var(--ds-white-color);
            }
        }

        &-button,
        &-close {
            position: absolute !important;
            top: 50%;
            transform: translateY(-50%);
        }

        &-button {
            right: 0.5rem;

            svg path {
                transition: fill var(--ds-main-transition);
            }

            &:focus:not([disabled]),
            &:hover {
                box-shadow: none !important;

                svg path {
                    fill: var(--ds-primary-color);
                }
            }
        }

        .spinner-border {
            position: absolute;
            right: 2.125rem;
            top: auto;
            width: 1.25rem;
            height: 1.25rem;
            --bs-spinner-border-width: 0.125em;

            @media (min-width: $break-md) {
                top: 0.5rem;
            }
        }

        &-close {
            left: 0.5rem;
        }
    }

    &-user {
        &--button {
            @media (max-width: $break-smallest) {
                .ds-dropdown-toggle>*:not(:first-child) {
                    display: none;
                }
            }
        }

        &-logged {
            padding: 0.75rem;
            background: var(--ds-light-bg-color);
        }
    }

    &-phone-button {
        position: static !important;

        @media (min-width: $break-md) {
            gap: 0.25rem;
            position: relative !important;
        }
    }

    &-cart-button {
        z-index: 1;
    }

    .badge {
        background-color: var(--ds-light-text-color);
        color: #fff;
        font-size: 11px;
        top: -0.375rem;
        right: -0.375rem;
        display: flex;
        text-align: center;
        justify-content: center;
        place-items: center;
        height: 14px;
        min-width: 14px;
        padding: 0 0.25rem;
        line-height: 1;
        z-index: 2;

        &.active {
            background-color: var(--ds-red-color);
        }
    }
}