.dark-theme {
    --ds-primary-dark-color: var(--ds-primary-dark-color-dark);
    --ds-secondary-dark-color: var(--ds-secondary-dark-color-dark);
    --ds-primary-light-color: var(--ds-primary-light-color-dark);
    --ds-light-bg-color: var(--ds-primary-light-color-dark);

    --ds-body-bg-color: var(--ds-body-bg-dark-color);
    --ds-footer-bg-color: var(--ds-footer-bg-dark-color);

    --ds-white-color: var(--ds-white-color-dark);

    --ds-dark-text-color: var(--ds-primary-dark-color);
    --ds-secondary-text-color: var(--ds-secondary-dark-color);
    --ds-light-text-color: var(--ds-light-text-color-dark);

    --ds-border-color: var(--ds-border-color-dark);
    --ds-main-border: 1px solid var(--ds-border-color);
    --ds-dashed-border: 1px dashed var(--ds-border-color);
    --ds-form-control-focus-color: var(--ds-form-control-focus-color-dark);

    --ds-light-shadow: var(--ds-light-shadow-dark);
    --ds-medium-shadow: var(--ds-medium-shadow-dark);
    --ds-dark-shadow: var(--ds-dark-shadow-dark);

    //others
    --ds-product-actions-bg: var(--ds-product-actions-bg-dark);
    --ds-maincategories-gradient: var(--ds-maincategories-gradient-dark);
    --ds-popup-close-btn-bg: var(--ds-popup-close-btn-bg-dark);
    --ds-popup-backdrop-bg: var(--ds-popup-backdrop-bg-dark);
    --ds-product-hover-shadow: var(--ds-product-hover-shadow-dark);

    --ds-dark-theme-links-color: #f8fcff;

    a.dark-text:not(:hover),
    .ds-menu-catalog a:not(:hover) {
        color: var(--ds-dark-theme-links-color);
    }

    .ds-advantages-item-title {
        color: var(--ds-dark-text-color) !important;
    }

    .ds-menu-maincategories-item > span img,
    .ds-menu-catalog-item-title img,
    .ds-menu-catalog-item-img {
        filter: invert(1);
    }

    .ds-menu-maincategories-item>svg path {
        fill: var(--ds-dark-text-color);
    }

    @media (min-width: $break-xl) {
        .ds-menu-catalog-item:hover {
            > a,
            > span a {
                color: var(--ds-links-color);
            }

            > svg path {
                fill: var(--ds-primary-light-color);
            }
            
            .ds-menu-catalog-item-title {
                img {
                    filter: invert(0);
                }
            }

            .ds-menu-catalog-item-img {
                filter: invert(0);
            }
        }
    }

    .ds-account-column-nav-item:hover {
        .dark-text {
            color: var(--ds-light-text-color);
        }
    }

    .ds-footer-payments-item {
        background: #818181;
    }

    .ds-product-tabs-item,
    .ds-product-tabs-item.active {
        background-color: #191919 !important;
    }

    #loginTabs .nav-link {
        background-color: #191919;
    }

    .form-control,
    .form-check-input {
        background-color: var(--ds-primary-light-color) !important;
    }

    .ds-slideshow-items::after {
        display: none;
    }

    .table>:not(caption)>*>* {
        background-color: #1c1c1c !important;
        color: var(--ds-secondary-dark-color) !important;
    }

    .table tbody tr:hover>* {
        --bs-table-bg-state: #131313 !important;
    }

    .ds-table-compare-head td {
        background-color: #0b0b0b !important;
    }

    .ds-header {
        box-shadow: var(--ds-light-shadow);
    }

    .modal-content {
        border: var(--ds-main-border);
    }

    .input-group-text:after {
        background-color: var(--ds-form-control-focus-color);
    }

    .product-list {
        .ds-module-attributes {
            background-color: var(--ds-light-bg-color);
        }
    }

    .ds-product-advantages-item-text-price {
        svg path {
            fill: var(--ds-primary-dark-color);
        }
    }

    .ds-contacts-item-social {
        background-color: var(--ds-secondary-dark-color-dark);
    }
}