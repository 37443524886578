.ds-cart {
    &-item {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: var(--ds-main-border);

        &-title {
            padding-right: 1rem;
            line-height: 1.2;

            a:hover {
                color: var(--ds-links-color);
                text-decoration: underline;
            }
        }

        .button-outline {
            border-color: var(--ds-border-color);
        }

        .no-btn {
            top: 0;
            right: 0;
            width: 14px;
            height: 14px;
            display: grid;
            place-items: center;

            svg {
                width: 12px;
                height: 12px;
                object-fit: contain;
            }
        }

        & + .ds-buy-one-click {
            border-top: none;

            @media (min-width: $break-md) {
                justify-content: flex-end;

                .input-group {
                    width: auto;
                }

                input[type="tel"] {
                    max-width: 200px;
                }
            }
        }

        &-img {
            img {
                border-radius: 0.25rem;
            }
        }

        @media (max-width: $break-smallest) {
            &-actions {
                flex-direction: column !important;
                align-items: flex-start !important;
                gap: 1rem;

                .ds-cart-item-price {
                    text-align: left !important;
                }
            }
        }
    }

    &-column {
        &-title,
        &-item {
            line-height: 1.2;
        }

        &-text,
        &-item {
            border-bottom: var(--ds-main-border);
        }

        &-item {
            &-title {
                cursor: pointer;

                svg {
                    transition: transform var(--ds-main-transition);

                    path {
                        fill: var(--ds-dark-text-color);
                    }
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            &-block-group {
                max-height: 0;
                overflow: hidden;
                transition: max-height var(--ds-main-transition);

                &.active {
                    max-height: 1000px;
                }
            }
        }

        .button-primary {
            @media (min-width: $break-sm) and (max-width: $break-md-max) {
                max-width: 300px;
            }
        }

        &-total {
            .progress-bar {
                background-color: var(--ds-primary-color);
            }

            &-item {
                > *:first-child {
                    text-align: left;
                }
                &-text {
                    white-space: nowrap;
                    flex-grow: 1;
                }
            }
        }
    }
}

#checkout-cart .ds-buy-one-click {
    border-top: 0;
}