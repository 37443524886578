.ds-manufacturer {
    &-item {
        &-block {
            width: calc(100% / 3);

            @media (min-width: $break-md) {
                width: calc(100% / 6);
            }

            @media (min-width: $break-lg) {
                width: calc(100% / 8);
            }

            @media (min-width: $break-xl) {
                width: calc(100% / 6);
            }

            @media (min-width: $break-xxl) {
                width: calc(100% / 8);
            }

            &:hover {
                .ds-manufacturer-item-title {
                    color: var(--ds-links-color);
                    text-decoration: underline;
                }
            }

            img {
                border-radius: 0.25rem;
                object-fit: contain;
                aspect-ratio: 1 / 1;
            }

            .content-block {
                gap: 0.5rem;
            }
        }

        &-title {
            font-size: 0.625rem;

            @media (min-width: $break-md) {
                font-size: 0.75rem;
            }
        }

        &:hover {
            box-shadow: none;
        }
    }

    &-links {

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0.25rem 0.5rem;
            border-radius: 7px;
            line-height: 1;

            &.active {
                background-color: var(--ds-light-bg-color);
                text-decoration: none;
            }
        }
    }

    &-title {
        border-bottom: var(--ds-main-border);
    }
}