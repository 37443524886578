.ocf-theme-light-block.ocf-vertical .ocf-filter {
    background-color: var(--ds-white-color) !important;
    border: var(--ds-main-border) !important;
}

.ocf-theme-light-block .ocf-filter-header .ocf-filter-name, .ocf-theme-light-block .ocf-filter-header .ocf-active-label, .ocf-theme-light-block .ocf-module-page-header, .ocf-header {
    color: var(--ds-dark-text-color) !important;
}

.ocf-value, .ocf-value:active, .ocf-value:focus, .ocf-value:visited, .ocf-value:hover, .ocf-selected-header {
    color: var(--ds-secondary-text-color) !important;
}

.ocf-theme-light-block .ocf-footer {
    background-color: var(--ds-white-color) !important;
    box-shadow: var(--ds-dark-shadow) !important;
}

.ocf-footer {
    margin: 16px 0 !important;
    padding: 12px !important;
}

#column-left-grid, #column-right-grid, #column-left, #column-right {
    z-index: 12 !important;
}

.ocf-popover {
    background-color: var(--ds-white-color) !important;
    box-shadow: var(--ds-medium-shadow) !important;
}

.ocf-popover>.ocf-arrow {
    background-color: var(--ds-white-color) !important;
}

.ocf-collapse-value .ocf-value-list {
    border-top: 0 !important;
}

.ocf-theme-light-block .ocf-scroll-y {
    background-image: none !important;
}

.ocf-theme-light-block .ocf-selected-filter {
    background-color: var(--ds-border-color) !important;
    color: var(--ds-dark-text-color) !important;
}

.ocf-theme-light-block .ocf-selected-value-name {
    color: var(--ds-dark-text-color) !important;
}

.ocf-theme-light-block .ocf-selected-filter-name {
    color: inherit !important;
}

.ocf-theme-light-block .ocf-value-count {
    background-color: var(--ds-primary-color) !important;
    color: var(--ds-white-color) !important;
    border-radius: 4px !important;
}

@media (max-width: 767px) {
    .ocf-content,
    .ocf-filter>.ocf-filter-body>.ocf-value-list {
        background-color: var(--ds-white-color) !important;
    }

    .ocf-theme-light-block .ocf-open .ocf-filter-header {
		background-color: var(--ds-light-bg-color) !important;
		color: var(--ds-dark-text-color) !important;
	}

    .ocf-btn-mobile-static {
        border-bottom: 0 !important;
        margin-bottom: 1rem !important;
        padding-bottom: 0 !important;
    }

    .ocf-body>.ocf-filter-list::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    
    .ocf-body>.ocf-filter-list {
        scrollbar-width: none;
    }
}