.ds-table-compare {
    border: 0;

    td {
        padding: 1rem;
        min-width: 180px;

        &:not(:first-child) {
            color: var(--ds-light-text-color);
        }

        &:first-child {
            color: var(--ds-dark-text-color);
        }

        &.green-text {
            color: var(--ds-green-color);
        }

        &.red-text {
            color: var(--ds-red-color);
        }
    }

    tr:last-child td {
        border-bottom: 0;
    }

    &-head {

        td {
            padding: 1.5rem;
            background-color: #FAFAFC;
            border-bottom: 0;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: var(--ds-secondary-text-color);
                }
            }
        }
    }

    &-img {
        img {
            border-radius: 7px;
            border: var(--ds-main-border);
            padding: 0.25rem;
        }
    }

    .ds-module-rating {
        padding: 0.25rem 0.5rem;
        background-color: var(--ds-light-bg-color);
    }

    .ds-module-rating-star + .ds-module-rating-star {
        margin-left: 0.25rem;
    }

    .button {
        padding: 0.5rem 0.75rem;
    }
}